<template>
  <div class="mister">
      <div class="uk-card uk-card-default uk-card-body ">
          <h1 class="uk-card-title">
              <slot name="pageTitle">{{ translate('sgirot.source.mainTitle') }}</slot>
          </h1>
          <div class="page-content">
              <crud-list
                  subject="source"
                  :enableIdColumn="true"></crud-list>
          </div>
      </div>

  </div>
</template>

<script>

import CrudList from '@/client/applications/sgirot/components/crud/List.vue';
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";

export default {

    setup () {
        return  useMeta({
            title: useI18n().t('sgirot.source.mainTitle')
       }).meta
    },
    props: {

    },
    middleware: {
        sgirotUser: {
            userTypes: [1]
        }
    },
    data () {
        return {
            entityType: 'source'
        }
    },
    components: {CrudList},
    layout: 'dashboard',
    methods: {
        getEditRoute(id) {
            return {name: 'entity-edit', params: {entityType: this.entityType, entityId: id}}
        },
        getUpdateApiUrl(arg) {
            return 'entity/'+this.entityType+'/'+arg;
        },
    }
}
</script>
