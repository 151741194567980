<template>
    <div class="mister">
        <div class="uk-card uk-card-default uk-card-body ">
            <h1 class="uk-card-title">
                <slot name="pageTitle">{{ translate('sgirot.expert.createTitle') }}</slot>
            </h1>
            <div class="page-content">
                <crud-create
                        :formProps="{fieldWrapperClass : 'uk-flex uk-flex-stretch uk-gap-m flex-break-large-start'}"
                        :formConfig="formConfig"
                        :formSource="entityType+'/create'"
                        itemSource=""
                        subject="expert"
                        :redirect="{name: 'expert'}"
                ></crud-create>
            </div>
        </div>

    </div>
</template>

<script>

import CrudCreate from '@/client/applications/sgirot/components/crud/Create.vue';
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import userFormFields from '@/client/applications/sgirot/forms/simpleUser.js';

export default {

    setup () {
        return  useMeta({
                            title: useI18n().t('sgirot.expert.createTitle')
                        }).meta
    },
    props     : {},
    middleware: {
        sgirotUser: {
            userTypes: [1]
        }
    },
    data() {
        let formFields = {
                ...userFormFields,
                phone            : {
                    name      : 'phone',
                    type      : 'text',
                    label     : 'sgirot.expert.fields.phone',
                    placeholder: 'sgirot.expert.fields.phonePlaceholder',
                    icon      : 'receiver',
                    class     : '',
                    id        : '',
                    validation: {},
                    group     : 'group2',
                },
                city             : {
                    name          : 'city',
                    type          : 'text',
                    label         : 'sgirot.expert.fields.city',
                    placeholder: 'sgirot.expert.fields.cityPlaceholder',
                    class         : '',
                    id            : '',
                    icon          : 'home',
                    isPrimaryField: true,
                    validation    : {},
                    group         : 'group2',
                },
                address          : {
                    name          : 'address',
                    type          : 'text',
                    label         : 'sgirot.expert.fields.address',
                    placeholder   : 'sgirot.expert.fields.addressPlaceholder',
                    class         : '',
                    icon          : 'location',
                    id            : '',
                    isPrimaryField: true,
                    validation    : {},
                    group         : 'group2',
                },
                commissionPercent: {
                    name          : 'commissionPercent',
                    type          : 'text',
                    icon          : 'info',
                    label         : 'sgirot.expert.fields.commissionPercent',
                    placeholder: 'sgirot.expert.fields.commissionPercentPlaceholder',
                    class         : '',
                    id            : '',
                    isPrimaryField: true,
                    validation    : {
                        required: {},
                        integer : {}
                    },
                    group         : 'group2',
                },

        };
        formFields.password.validation.required = {};
        formFields.email.validation.required = {};
        formFields.email.validation.liveServerSide = {
            message : 'sgirot.user.emailInUseError',
                params:  {
                'url' : 'expert/email',
                    'data': {

                }
            },
        }

        return {
            entityType: 'source',
            formConfig: {
                fields: formFields,
                groups : {
                    group1: {
                        component: 'FormFieldSet',
                        props    : {
                            title: 'sgirot.expert.fields.userFieldsetTitle',
                            icon : 'user',
                            class: 'uk-width-xlarge uk-margin-top',

                        }
                    },
                    group2: {
                        component: 'FormFieldSet',
                        props    : {
                            title: 'sgirot.expert.fields.expertFieldsetTitle',
                            icon : 'info',
                            class: 'uk-width-xlarge uk-margin-top'
                        }
                    }
                },
                buttons: {},
                context: 'edit',
            }
        }
    },
    components: {CrudCreate},
    layout    : 'dashboard',
    methods   : {}
}
</script>
