<template>

    <layout-admin-dashboard-view
        :showToolbar="false"
        :backRoute="{name: 'admin-dashboard'}">
        <template v-slot:title>
            <span>{{translateTitleCase('sgirot.admin.sgirotAdminViewTitle')}}</span>
        </template>
        <template v-slot:default>
            <button-group class="uk-container">
                <form-button
                    :loading="whatsappGroupsLoading"
                    @click="showWhatsappGroups" >{{translate('sgirot.admin.action.showWhatsappGroups')}}</form-button>
            </button-group>
            <div class="whatsapp-group-block uk-container uk-animation-fade uk-margin-large-top relative" v-if="fetchedWhatsappGroups">
                <h3 class="uk-margin-remove-bottom">{{ translate('sgirot.admin.whatsapp.groupsTitle') }}</h3>
                <span class="uk-margin-large-bottom"> {{translate('sgirot.admin.whatsapp.groupsDisplayCountStatus', {current: filteredWhatsappGroups.length, total: this.whatsappGroupsCount})}}</span>
                <div class="uk-width-medium">
                    <form-input icon="search" :label="false" placeholder="sgirot.admin.whatsapp.filterPlaceholder"
                                name="whatsapp-group-filter"
                                v-model="whatsappGroupsFilter"></form-input>
                </div>

                <spinner :show="whatsappGroupsLoading" overlay="absolute"></spinner>


                <div class="group-list-container uk-flex uk-flex-wrap uk-flex-stretch uk-gap">
                    <div class="uk-card uk-width-1-3@m uk-width-1-4@l " v-for="(group,index) of filteredWhatsappGroups" :key="index">
                        <div class="uk-card-body uk-card-default">
                            <h3 class="uk-card-title">{{ group.name }}</h3>
                            <div class="group-info-block uk-flex uk-flex-between"
                                 v-for="(key) of ['name','id','isGroup']" :key="index + '-' + key">
                                <strong>{{key}}</strong>
                                <span>{{group[key]}}</span>
                            </div>
                        </div>
                    </div>

                </div>
                <hr>
            </div>
        </template>
    </layout-admin-dashboard-view>
</template>

<script>
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import {useStore} from "vuex";
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';

export default {
    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({
                     title: useI18n().t('sgirot.admin.sgirotAdminViewPageTitle')
                 }).meta;
        return {asyncOps, asyncOpsReady, asyncStatus, meta};

    },
    props : {

    },
    middleware: {
      requireSuperAdmin : {},
    },
    layout: 'adminDashboard',
    data: function () {
        return {
            whatsappGroupsFilter : '',
            whatsappGroupsLoading:  false,
            fetchedWhatsappGroups :false,
            whatsappGroupsCount : 0,
            whatsappGroups: [],
            whatsappGroupsUrl : 'sgirot-admin/whatsapp/groups',
        };
    },
    computed: {
        filteredWhatsappGroups() {

            if ( ! Array.isArray(this.whatsappGroups)) {
                return []
            }

            if ( ! this.whatsappGroupsFilter || this.whatsappGroupsFilter == '') {
                return this.whatsappGroups;
            }

            return this.whatsappGroups.filter(group => group.name.includes(this.whatsappGroupsFilter));

        }
    },
    methods: {
        async showWhatsappGroups() {
            this.whatsappGroupsLoading = true;
            await utilities.wait(2000);
            let result = await this.asyncOps.asyncCall(this.whatsappGroupsUrl);


            this.whatsappGroupsLoading = false;
            if ( result.isError) {
                this.$s.ui.notification(this.translate('sgirot.general.genericError'))
                return;
            }

            this.whatsappGroupsCount = result.data.itemCount;
            this.whatsappGroups = result.data.items;
            this.fetchedWhatsappGroups = true;
            this.whatsappGroupsLoading = false;
        }
    }


}
</script>

<style scoped lang="scss">

</style>
